import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { Calendar } from '@/shadcn/components/ui/calendar.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '@/shadcn/components/ui/popover.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { CalendarIcon, Undo2 } from 'lucide-react'
import { DateTime } from 'luxon'
import { Matcher } from 'react-day-picker'

export function DateSelect(props: {
  startDate: DateTime
  setStartDate: (date: DateTime) => void
  title: string
  placeholder: string
  label: string
  optional?: boolean
  calendarDisabledMatcher?: Matcher | Matcher[]
  focusMonth?: DateTime
  disabled?: boolean
}) {
  return (
    <>
      <label className="mb-1 block text-sm font-medium leading-6 text-gray-900">
        {props.title}
        {props.optional && (
          <Badge variant="indigoBezel" className="ml-2">
            Optional
          </Badge>
        )}
      </label>
      <div className="flex items-center gap-x-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              disabled={props.disabled}
              variant="outline"
              className={cn(
                'w-[280px] justify-between bg-white text-left font-normal',
                !props.startDate && 'text-muted-foreground',
              )}
            >
              {props.startDate ? (
                props.startDate.toLocaleString(DateTime.DATE_HUGE)
              ) : (
                <span>{props.placeholder}</span>
              )}
              <CalendarIcon className="mr-2 h-4 w-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              defaultMonth={props.focusMonth?.toJSDate()}
              disabled={props.calendarDisabledMatcher || props.disabled}
              mode="single"
              selected={props.startDate?.toJSDate()}
              onSelect={(date) => {
                props.setStartDate(date ? DateTime.fromJSDate(date) : null)
              }}
              initialFocus
            />
          </PopoverContent>
        </Popover>
        {props.startDate && (
          <Button
            disabled={props.disabled}
            className="ml-1 bg-gray-200 hover:bg-gray-100"
            variant="secondary"
            onClick={() => {
              props.setStartDate(undefined)
            }}
            size="icon"
          >
            <Undo2 className="size-4" />
          </Button>
        )}
      </div>
      <p className="mt-1 text-xs text-muted-foreground">{props.label}</p>
    </>
  )
}
