import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { MeetingEntity } from '../../../qourses-api-client'

interface MeetingFilters {
  instructorId?: string
  courseId?: string
  courseGroupId?: string
  startDate?: Date
  endDate?: Date
}

export default function useGetMeetings(filters?: MeetingFilters): {
  meetings: MeetingEntity[]
  isLoading: boolean
  isEmptyMeetings: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: meetings,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['meeting', filters], async () => {
    return qoursesApi.meeting.meetingControllerGetMeetings(
      filters?.instructorId,
      filters?.courseId,
      filters?.courseGroupId,
      filters?.startDate?.toISOString(),
      filters?.endDate?.toISOString(),
    )
  })

  const isEmpty = !meetings || meetings.length === 0

  return {
    meetings: meetings || [],
    isLoading,
    isEmptyMeetings: isEmpty,
    isError,
    refetch,
    isRefetching,
  }
}

export const getMeetingsQueryKey = (filters?: MeetingFilters) => ['meeting', filters]
