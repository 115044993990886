import { useQuery } from '@tanstack/react-query'
import { MultipassProductWithCourseIdsEntityDto } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetMultipassProducts(): {
  multipassProducts: MultipassProductWithCourseIdsEntityDto[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: multipassProducts,
    isLoading,
    isError,
  } = useQuery(['multipassProducts'], async () => {
    return qoursesApi.multipass.multipassControllerGetMultipassProducts()
  })

  let isEmpty = true

  if (multipassProducts === undefined) {
    return { multipassProducts: [], isLoading, isEmpty, isError }
  }

  if (multipassProducts.length > 0) {
    isEmpty = false
  }

  return { multipassProducts, isLoading, isEmpty, isError }
}
