import { useQuery } from '@tanstack/react-query'
import { CourseEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCourse(courseId: string): {
  course: CourseEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: course,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['course', courseId], async () => {
    return qoursesApi.course.courseControllerGetCourse(courseId)
  })

  return { course, isLoading, isError, refetch, isRefetching }
}
