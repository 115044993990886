import { setTokenInstructor } from '@/api/qourses-instructor.tsx'
import OnboardingScreen from '@/components/OnboardingScreen.tsx'
import { ModalProvider } from '@/components/modals'
import GlobalErrorPage from '@/pages/GlobalErrorPage.tsx'
import FormDetail from '@/pages/forms/FormDetail.tsx'
import { Toaster } from '@/shadcn/components/ui/sonner.tsx'
import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Settings as LuxonSettings } from 'luxon'
import { Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom'
import { qoursesApi, setToken } from './api/qourses.tsx'
import { releaseVersion } from './metadata.json'
import LoadingLogo from './pages/LoadingLogo.tsx'

try {
  Sentry.init({
    enabled: !!import.meta.env.VITE_SENTRY_ENABLED,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: import.meta.env.VITE_SENTRY_ENV,
    tracesSampleRate: 1.0,
    release: releaseVersion ? releaseVersion : undefined,
  })
} catch (e) {
  // Don't do anything if sentry fails to initialize (get's blocked)
}

const Auth0ProviderOutlet = () => {
  const domain = import.meta.env.VITE_AUTH_DOMAIN
  const clientId = import.meta.env.VITE_AUTH_CLIENT
  const audience = import.meta.env.VITE_AUTH_AUDIENCE
  const redirectUri = import.meta.env.VITE_AUTH_REDIRECT_URI

  const navigate = useNavigate()
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        scope: 'offline_access profile email',
      }}
      cacheLocation={'memory'}
      onRedirectCallback={onRedirectCallback}
    >
      <Outlet />
    </Auth0Provider>
  )
}

const PublicOutlet = () => {
  document.title = 'Qourses'

  return (
    <Suspense fallback={<LoadingLogo />}>
      <link rel={'preconnect'} href={qoursesApi.request.config.BASE} />
      <link rel={'preconnect'} href={'https://api.mapbox.com'} />
      <Outlet />
    </Suspense>
  )
}

const TokenOutlet = () => {
  const { getAccessTokenSilently } = useAuth0()

  const { isLoading } = useQuery(['authToken'], async () => {
    try {
      const token = await getAccessTokenSilently({ cacheMode: 'on' })
      setToken(token)
      setTokenInstructor(token)
      return token
    } catch (e) {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.error === 'login_required'
      ) {
        // We do not error here because we just may not be authed, it's okay!
        //console.error(e)
        return null
      }
      console.error('Error getting token silently: ' + e)
    }
  })

  if (isLoading) {
    return <div></div>
  }

  return <Outlet />
}

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 30000,
        staleTime: 15000,
      },
    },
  })

  const { i18n } = useTranslation()
  LuxonSettings.defaultLocale = i18n.language

  const stripeRefreshUri = import.meta.env.VITE_STRIPE_ONBOARDING_REFRESH_PATH
  const stripeOnboardingRedirectUri = import.meta.env.VITE_STRIPE_ONBOARDING_REDIRECT_PATH
  const stripeBookingSuccessUri = import.meta.env.VITE_STRIPE_PAYMENT_SUCCESS_REDIRECT_PATH
  const stripeBookingFailedUri = import.meta.env.VITE_STRIPE_PAYMENT_CANCELED_REDIRECT_PATH

  const StripeRefresh = lazy(() => import('./pages/StripeOnboardingRefresh.tsx'))
  const StripeRedirect = lazy(() => import('./pages/StripeOnboardingRedirect.tsx'))
  const CreateCourse = lazy(() => import('./pages/courses/CreateCourse.tsx'))
  const CreateCourseMeetings = lazy(() => import('./pages/courses/CreateCourseMeetings.tsx'))
  const CreateCourseGroupMeetings = lazy(
    () => import('./pages/courses/CreateCourseGroupMeetings.tsx'),
  )
  const CreateCourseGroup = lazy(() => import('./pages/courses/CreateCourseGroup.tsx'))
  const Courses = lazy(() => import('./pages/courses/Courses.tsx'))
  const CourseDetail = lazy(() => import('./pages/courses/CourseDetail.tsx'))
  const Instructors = lazy(() => import('./pages/instructors/Instructors.tsx'))
  const Meetings = lazy(() => import('./pages/meetings/Meetings.tsx'))
  const CreateInstructor = lazy(() => import('./pages/instructors/CreateInstructor.tsx'))
  const InstructorDetail = lazy(() => import('./pages/instructors/InstructorDetail.tsx'))
  const Locations = lazy(() => import('./pages/locations/Locations.tsx'))
  const CreateLocation = lazy(() => import('./pages/locations/CreateLocation.tsx'))
  const LocationDetail = lazy(() => import('./pages/locations/LocationDetail.tsx'))
  const Settings = lazy(() => import('./pages/Settings.tsx'))
  const UserSettings = lazy(() => import('./pages/UserSettings.tsx'))
  const MeetingDetail = lazy(() => import('./pages/meetings/MeetingDetail.tsx'))
  const CourseGroupDetail = lazy(() => import('./pages/meetings/CourseGroupDetail.tsx'))
  const Members = lazy(() => import('./pages/members/Members.tsx'))
  const AcceptInvitation = lazy(() => import('./pages/AcceptInvitation.tsx'))
  const Login = lazy(() => import('./pages/Login.tsx'))
  const SidebarLayoutOutlet = lazy(() => import('./SidebarLayoutOutlet.tsx'))
  const CreateMultipassProduct = lazy(
    () => import('./pages/multipasses/CreateMultipassProduct.tsx'),
  )
  const Multipasses = lazy(() => import('./pages/multipasses/Multipasses.tsx'))
  const MultipassProductDetail = lazy(
    () => import('./pages/multipasses/MultipassProductDetail.tsx'),
  )
  const IssueMultipassProduct = lazy(() => import('./pages/multipasses/IssueMultipassProduct.tsx'))
  const Customers = lazy(() => import('./pages/customers/Customers.tsx'))
  const CustomerDetail = lazy(() => import('./pages/customers/CustomerDetail.tsx'))
  const GuestsDetail = lazy(() => import('./pages/customers/GuestDetail.tsx'))
  const BookingPage = lazy(() => import('./pages/public/booking/Bookings.tsx'))
  // const EmbeddedStripeCheckout = lazy(
  //   () => import('./pages/public/booking/EmbeddedStripeCheckout.tsx'),
  // )
  const BookingSuccessful = lazy(() => import('./pages/public/booking/BookingSuccessful.tsx'))
  const BookingCanceled = lazy(() => import('./pages/public/booking/BookingCanceled.tsx'))
  const OrganizationTermsOfService = lazy(
    () => import('./pages/public/legal/OrganizationTermsOfService.tsx'),
  )
  const OrganizationRevocationPolicy = lazy(
    () => import('./pages/public/legal/OrganizationRevocationPolicy.tsx'),
  )

  const LegalDocuments = lazy(() => import('./pages/legal/LegalDocuments.tsx'))
  const TermsOfServiceDetail = lazy(() => import('./pages/legal/TermsOfServiceDetail.tsx'))
  const RevocationPolicyDetail = lazy(() => import('./pages/legal/RevocationPolicyDetail.tsx'))
  const LegalTermsDetail = lazy(() => import('./pages/legal/LegalTermDetail.tsx'))

  const MePage = lazy(() => import('@/pages/user/MePage.tsx'))
  const UserOrderDetail = lazy(() => import('@/pages/user/UserOrderDetail.tsx'))

  const GuestClaiming = lazy(() => import('@/pages/user/GuestClaiming.tsx'))

  const Forms = lazy(() => import('@/pages/forms/Forms.tsx'))

  const Orders = lazy(() => import('@/pages/orders/Orders.tsx'))
  const OrderDetail = lazy(() => import('@/pages/orders/OrderDetail.tsx'))
  const BookingDetail = lazy(() => import('@/pages/orders/BookingDetail.tsx'))
  const OrderFormQuestions = lazy(() => import('@/pages/public/survey/FormQuestionsSubmission.tsx'))
  const CreateOrder = lazy(() => import('@/pages/orders/CreateOrder.tsx'))

  const OrderFormQuestionSubmissions = lazy(
    () => import('@/pages/orders/OrderFormQuestionSubmissions.tsx'),
  )

  const Organizations = lazy(() => import('@/pages/platformAdmin/Organizations.tsx'))
  const Pulse = lazy(() => import('@/pages/platformAdmin/Pulse.tsx'))

  const NotFoundPage = lazy(() => import('@/pages/NotFoundPage.tsx'))

  const CreateCourseSchedule = lazy(() => import('@/pages/schedules/CreateCourseSchedule.tsx'))

  const ManageScheduleBatches = lazy(() => import('@/pages/meetings/ManageScheduleBatches.tsx'))
  const CourseScheduleDetail = lazy(() => import('@/pages/schedules/CourseScheduleDetail.tsx'))

  const routeElements = createRoutesFromElements(
    // Everything in here is public
    <Route element={<PublicOutlet />} path={'/'} errorElement={<GlobalErrorPage />}>
      <Route index={true} element={<Navigate to={'courses'} />} />
      <Route element={<Auth0ProviderOutlet />}>
        <Route path={'login'} element={<Login />} />
        <Route element={<TokenOutlet />}>
          <Route path={'*'} element={<NotFoundPage />} />
          <Route path={'claimGuest/:guestId'} element={<GuestClaiming />} />
          <Route path={'me'} element={<MePage />} />
          <Route path={'me/orders/:orderId'} element={<UserOrderDetail />} />
          <Route path={'book'} element={<BookingPage />} />
          {/*<Route path={'checkout'} element={<EmbeddedStripeCheckout />} />*/}
          <Route path={'survey'} element={<OrderFormQuestions />} />
          <Route path={'book/legal/tos/:tosId'} element={<OrganizationTermsOfService />} />
          <Route
            path={'book/legal/revocationPolicy/:revocationPolicyId'}
            element={<OrganizationRevocationPolicy />}
          />
          <Route path={stripeBookingSuccessUri} element={<BookingSuccessful />} />
          <Route path={stripeBookingFailedUri} element={<BookingCanceled />} />
          {/*This is warapped into an authguard so this can only be shown to logged in users*/}
          <Route
            path={'onboarding'}
            element={<AuthenticationGuard component={OnboardingScreen} />}
          />
          <Route path={'invite'} element={<AuthenticationGuard component={AcceptInvitation} />} />
          <Route path={stripeRefreshUri} element={<StripeRefresh />} />
          <Route path={stripeOnboardingRedirectUri} element={<StripeRedirect />} />
          <Route element={<AuthenticationGuard component={SidebarLayoutOutlet} />}>
            <Route path={'/admin'}>
              <Route path={'organizations'} element={<Organizations />} />
              <Route path={'pulse'} element={<Pulse />} />
            </Route>
            <Route path={'courses'}>
              <Route index={true} element={<Courses />} />
              <Route path={'create'} element={<CreateCourse />} />
              <Route path={'createGroup'} element={<CreateCourseGroup />} />
              <Route path={':courseId'} element={<CourseDetail />} />
            </Route>
            <Route path={'meetings'}>
              <Route index={true} element={<Meetings />} />
              <Route path={'create'} element={<CreateCourseMeetings />} />
              <Route path={'schedules/create'} element={<CreateCourseSchedule />} />
              <Route path={'schedules/batches'} element={<ManageScheduleBatches />} />
              <Route path={'schedules/:courseScheduleId'} element={<CourseScheduleDetail />} />
              <Route path={'create/courseGroup'} element={<CreateCourseGroupMeetings />} />
              <Route path={':meetingId'} element={<MeetingDetail />} />
              <Route path={'courseGroup/:courseGroupId'} element={<CourseGroupDetail />} />
            </Route>
            <Route path={'instructors'}>
              <Route index={true} element={<Instructors />} />
              <Route path={'create'} element={<CreateInstructor />} />
              <Route path={':instructorId'} element={<InstructorDetail />} />
            </Route>
            <Route path={'members'}>
              <Route index={true} element={<Members />} />
            </Route>
            <Route path={'multipasses'}>
              <Route index={true} element={<Multipasses />} />
              <Route path={'create'} element={<CreateMultipassProduct />} />
              <Route path={':multipassProductId'} element={<MultipassProductDetail />} />
              <Route path={':multipassProductId/issue'} element={<IssueMultipassProduct />} />
            </Route>
            <Route path={'orders'}>
              <Route index={true} element={<Orders />} />
              <Route path={':orderId'} element={<OrderDetail />} />
              <Route path={':orderId/bookings/:bookingId'} element={<BookingDetail />} />
              <Route path={':orderId/formSubmissions'} element={<OrderFormQuestionSubmissions />} />
              <Route path={'create'} element={<CreateOrder />} />
            </Route>
            <Route path={'customers'}>
              <Route index={true} element={<Customers />} />
              <Route path={':customerId'} element={<CustomerDetail />} />
            </Route>
            <Route path={'guests'}>
              <Route path={':guestId'} element={<GuestsDetail />} />
            </Route>
            <Route path={'settings'}>
              <Route index={true} element={<Settings />} />
              <Route path={'legal'}>
                <Route index={true} element={<LegalDocuments />} />
                {/* This there to make breadcrumbs work with tabs*/}
                <Route
                  path={'revocationPolicy'}
                  element={<Navigate to={'/settings/legal?tab=revocationPolicy'} />}
                />
                <Route path={'tos'} element={<Navigate to={'/settings/legal?tab=tos'} />} />
                <Route
                  path={'legalTerms'}
                  element={<Navigate to={'/settings/legal?tab=legalTerms'} />}
                />
                {/* This there to make breadcrumbs work with tabs*/}
                <Route path={'tos/:tosId'} element={<TermsOfServiceDetail />} />
                <Route
                  path={'revocationPolicy/:revocationPolicyId'}
                  element={<RevocationPolicyDetail />}
                />
                <Route path={'legalTerms/:legalTermId'} element={<LegalTermsDetail />} />
              </Route>
              <Route path={'locations'}>
                <Route index={true} element={<Locations />} />
                <Route path={'create'} element={<CreateLocation />} />
                <Route path={':locationId'} element={<LocationDetail />} />
              </Route>
              <Route path={'forms'}>
                <Route index={true} element={<Forms />} />
                <Route path={':id'} element={<FormDetail />} />
              </Route>
            </Route>
            <Route path={'user-settings'} element={<UserSettings />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  )

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = sentryCreateBrowserRouter(routeElements)

  return (
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary>
        <Toaster position={'top-right'} />
        <ModalProvider />
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        <RouterProvider router={router} />
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  )
}

export const AuthenticationGuard = ({ component }: any) => {
  const Component = withAuthenticationRequired(component)
  return <Component />
}
