import ApproveScheduleBatchModal from '@/components/modals/ApproveScheduleBatchModal.tsx'
import CancelMeetingModal from '@/components/modals/CancelMeetingModal.tsx'
import { CreateBookingLinkModal } from '@/components/modals/CreateBookingLinkModal.tsx'
import { CreateScheduleBatchModal } from '@/components/modals/CreateScheduleBatchModal.tsx'
import CustomerRefundBookingOptionsModal from '@/components/modals/CustomerRefundBookingOptionsModal.tsx'
import RefundBookingOptionsModal from '@/components/modals/RefundBookingOptionsModal.tsx'
import { TransferBookingModal } from '@/components/modals/TransferBookingModal.tsx'
import UpdateCustomerNameModal from '@/components/modals/UpdateCustomerNameModal.tsx'
import UpdateInstructorProfileModal from '@/components/modals/UpdateInstructorProfileModal.tsx'
import { UpdateMeetingTimeModal } from '@/components/modals/UpdateMeetingTimeModal.tsx'
import { UpdateOrganizationalBookingModal } from '@/components/modals/UpdateOrganizationalBookingModal.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { createPushModal } from 'pushmodal'

export const { pushModal, popModal, popAllModals, ModalProvider } = createPushModal({
  modals: {
    RefundBookingOptionsModal: {
      Component: RefundBookingOptionsModal,
      Wrapper: Dynamic.Wrapper,
    },
    TransferBookingModal: {
      Component: TransferBookingModal,
      Wrapper: Dynamic.Wrapper,
    },
    CustomerRefundBookingOptionsModal: {
      Component: CustomerRefundBookingOptionsModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateMeetingTimeModal: {
      Component: UpdateMeetingTimeModal,
      Wrapper: Dynamic.Wrapper,
    },
    DeleteMeetingConfirmationModal: {
      Component: CancelMeetingModal,
      Wrapper: Dynamic.Wrapper,
    },
    CreateBookingLinkModal: {
      Component: CreateBookingLinkModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateOrganizationalBookingModal: {
      Component: UpdateOrganizationalBookingModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateCustomerName: {
      Component: UpdateCustomerNameModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateInstructorProfileModal: {
      Component: UpdateInstructorProfileModal,
      Wrapper: Dynamic.Wrapper,
    },
    ApproveScheduleBatchModal: {
      Component: ApproveScheduleBatchModal,
      Wrapper: Dynamic.Wrapper,
    },
    CreateScheduleBatchModal: {
      Component: CreateScheduleBatchModal,
      Wrapper: Dynamic.Wrapper,
    },
  },
})
